import React from 'react';
import {Box, Container, createStyles, Grid} from '@material-ui/core';
import { BreadcrumbsComponent, breadCrumbsSettings } from '@platform/ics-front-core';
import { PassportRow } from '../../../models/passport/PassportListModel';
import { observer } from 'mobx-react';
import { PassportActionsBtns } from './PassportActionsBtns';
import { PassportRequestTable } from './PassportRequestTable';
import {makeStyles} from "@material-ui/core/styles";

const usePassportsListPageNewStyles = makeStyles(() =>
    createStyles({
        tableWrapper: {
            width: '100%',
        },
    }),
);

export const PassportsListPageNew = observer(() => {
    const setRowActions = (row: PassportRow): JSX.Element => {
        return <PassportActionsBtns passportRow={row} />;
    };

    const classes = usePassportsListPageNewStyles();

    return (
        <Container maxWidth="lg">
            <Box pt={5.5} pb={15}>
                <Box pb={5}>
                    <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} />
                </Box>
                <Grid container direction="column">
                    <Grid item className={classes.tableWrapper}>
                        <PassportRequestTable setRowActions={setRowActions} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});

const campaignRequest: Record<string, Record<string, string> | string> = {
    registryColumns: {
        direction: 'Direction',
        institute: 'Institute',
        department: 'Department',
        campaign: 'Subroutine',
    },
};

export default campaignRequest;

import { action, observable } from 'mobx';
import {
    Api,
    DateRangeFilter,
    IdTitleParent,
    InSetFilter,
    LikeFilter,
    RowsData,
    Sort,
    TableModel,
    TableQueryData,
    apiConfigs as apiConfigsICS,
    GroupedIdTitle,
} from '@platform/ics-front-core';
import apiConfigs from '../../apiConfigs';

export type RelatedObjectRowDto = {
    id: string;
    number: string;
    regFormTitle: string;
    created: string; //date
    sent: string; //date
    quarter: string;
    yearQuarter: string;
    state: string;
};

export type RelatedObjectSort = {
    number: Sort;
    regForm: Sort;
    created: Sort;
    sent: Sort;
};

export type RelatedObjectFilter = {
    number: LikeFilter;
    regForm: InSetFilter;
    state: InSetFilter;
    created: DateRangeFilter;
    sent: DateRangeFilter;
};

export class PassportRelatedListModel extends TableModel<RelatedObjectRowDto, RelatedObjectFilter, RelatedObjectSort> {
    @observable api: Api;
    @observable passportId: string;
    @observable regFormFilterData: IdTitleParent[];
    @observable stateFilterData: GroupedIdTitle;

    constructor(api: Api, passportId: string) {
        const filter = {
            number: new LikeFilter(),
            regForm: new InSetFilter(),
            state: new InSetFilter(),
            created: new DateRangeFilter(),
            sent: new DateRangeFilter(),
        };

        const sort = {
            number: new Sort('asc'),
            regForm: new Sort(),
            created: new Sort(),
            sent: new Sort(),
        };
        super(filter, sort);
        this.passportId = passportId;
        this.api = api;
        this.regFormFilterData = [];
        this.stateFilterData = {};
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<RelatedObjectRowDto>> {
        return this.api
            .client(apiConfigs.relatedObjectList(queryData, this.passportId))
            .then((r) => r.data)
            .then(({ rows, count }) => ({ rows, count }));
    }

    @action.bound
    getFilterData(passportId: string): void {
        const data = {
            filter: {},
        };

        this.api
            .client(apiConfigs.regFormsFilterData(passportId))
            .then((r) => r.data)
            .then(
                action((data) => {
                    this.regFormFilterData = data;
                }),
            );

        this.api
            .client(apiConfigsICS.requestListFilterData(data))
            .then((r) => r.data)
            .then(
                action((data) => {
                    if (data.statesByProcess) {
                        this.stateFilterData = data.statesByProcess;
                    }
                }),
            );
    }
}

import React, { createRef, ReactNode, useCallback, useEffect, useMemo } from 'react';
import {
    AuthorizationCheck,
    ISidebarItem,
    RenderChildren,
    Sidebar,
    useCampaignRequestPageStylesInj as useCampaignRequestPageStyles,
    useStore,
} from '@platform/ics-front-core';
import { Box, Button, createStyles, Grid, Icon, TabsActions } from '@material-ui/core';
import classNames from 'classnames';
import { RootStore } from '../../../di/store/RootStore';
import { PassportModel } from '../../../models/passport/PassportModel';
import { ReactComponent as QuestionIcon } from '../../../resources/images/icons/question-circle.svg';
import { observer } from 'mobx-react';
import { PassportTabs } from './PassportTabs';
import { makeStyles } from '@material-ui/core/styles';
import { PassportControlPanel } from './passport-control-panel/PassportControlPanel';
import { clientRoute } from '../../../clientRoute';

import { generatePath, NavLink, Route } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { FormattedMessage } from 'react-intl';

const usePassportPageStyles = makeStyles(() =>
    createStyles({
        contentContainer: {
            width: '100%',
        },
        sidebarGridEmpty: {
            '& div': {
                width: 'auto',
            },
        },
    }),
);

export type PassportPageProps = {
    children: RenderChildren | ReactNode;
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isWithSideBar?: boolean;
    passportModel: PassportModel;
    locale: string;
    formIsChanged: boolean;
    setFormIsChanged?: React.Dispatch<React.SetStateAction<boolean>>;
    reloadData: () => void;
};

export const PassportPage = observer(
    (props: PassportPageProps): JSX.Element => {
        const {
            children,
            isWithSideBar,
            passportModel,
            sidebarOpen,
            setSidebarOpen,
            formIsChanged,
            setFormIsChanged,
            reloadData,
        } = props;
        const { id, campaign } = passportModel;
        const campaignId = campaign.id;
        const campaignRequestClasses = useCampaignRequestPageStyles();
        const passportPageClasses = usePassportPageStyles();

        const rootStore = useStore() as RootStore;
        const { formioSidebarStore, intlStore } = rootStore;
        const { locale } = intlStore;
        const { items } = formioSidebarStore;
        const sidebarItems: ISidebarItem[] = isWithSideBar ? items[passportModel.formName] : [];
        const requestQuestion = intlStore.formatMessage('passport.requestQuestion');

        const tabsActions = useMemo(() => {
            return createRef<TabsActions>();
        }, []);

        useEffect(() => {
            setFormIsChanged && setFormIsChanged(false);
        }, [locale]);

        const toggleSidebar = useCallback(() => {
            setSidebarOpen((open) => !open);
            setTimeout(() => tabsActions.current?.updateScrollButtons(), 200);
        }, [setSidebarOpen, setTimeout, tabsActions]);

        return (
            <>
                <Grid item className={campaignRequestClasses.wrapper}>
                    <Box
                        pt={10}
                        pl={5}
                        pr={5}
                        className={classNames(campaignRequestClasses.container, {
                            [campaignRequestClasses.containerLg]: sidebarOpen,
                            [campaignRequestClasses.containerFullWidth]: !sidebarOpen,
                        })}
                    >
                        <Grid container spacing={7} wrap="nowrap">
                            <Grid
                                item
                                className={
                                    isWithSideBar
                                        ? classNames({
                                              [campaignRequestClasses.form]: sidebarOpen,
                                              [campaignRequestClasses.fullWidthForm]: !sidebarOpen,
                                          })
                                        : passportPageClasses.contentContainer
                                }
                            >
                                <Box pb={9}>
                                    <Box pb={5}>
                                        <Grid container justify="space-between" alignItems="center">
                                            <Grid item xs={10}>
                                                <PassportTabs passportModel={passportModel} tabsActions={tabsActions} />
                                            </Grid>
                                            <Grid item>
                                                <Route
                                                    path={clientRoute.passportRequests}
                                                    key={clientRoute.passportRequests}
                                                    exact
                                                >
                                                    <AuthorizationCheck
                                                        entityCode={entities.Passport}
                                                        permCode={permissions.Passport.EditRelatedRequests}
                                                    >
                                                        <React.Fragment>
                                                            {campaignId && (
                                                                <Grid
                                                                    container
                                                                    justify="space-between"
                                                                    alignItems="center"
                                                                >
                                                                    <Grid item>
                                                                        <Icon color="secondary">
                                                                            <QuestionIcon title={requestQuestion} />
                                                                        </Icon>
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        <Button
                                                                            color="primary"
                                                                            size="medium"
                                                                            variant="contained"
                                                                            type="submit"
                                                                            component={NavLink}
                                                                            to={generatePath(
                                                                                clientRoute.relatedObjectsRegForms,
                                                                                {
                                                                                    campaignId,
                                                                                    passportId: id,
                                                                                },
                                                                            )}
                                                                        >
                                                                            <FormattedMessage id="campaign.createRequest" />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                        </React.Fragment>
                                                    </AuthorizationCheck>
                                                </Route>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box width="100%">{children}</Box>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                className={classNames(campaignRequestClasses.sidebarGridWrapper, {
                                    [passportPageClasses.sidebarGridEmpty]: sidebarItems && !sidebarItems.length,
                                })}
                            >
                                <Sidebar open={sidebarOpen} toggleSidebar={toggleSidebar} sidebarItems={sidebarItems} />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Route path={[clientRoute.passport, clientRoute.passportEdit]} exact>
                    <Grid item>
                        <PassportControlPanel
                            model={passportModel}
                            formIsChanged={formIsChanged}
                            reloadData={reloadData}
                        />
                    </Grid>
                </Route>
            </>
        );
    },
);

import React from 'react';
import { Button, createStyles, Grid, makeStyles } from '@material-ui/core';
import { NavLink, match as Match } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { FormatListNumbered } from '@material-ui/icons';
import { ReactComponent as FileIcon } from '../../../resources/images/icons/file-document-box-multiple-outline.svg';
import { FormattedMessage } from 'react-intl';
import { injectable } from 'react-magnetic-di';
import { AdditionalLinksInj, AuthorizationCheck, entities } from '@platform/ics-front-core';
import { Location } from 'history';
import { observer } from 'mobx-react';
import { permissions } from '../../../authSchemeConfig';

export const useAdditionalLinksStyles = makeStyles(() =>
    createStyles({
        buttonIcon: {
            fontSize: '24px !important',
        },
    }),
);

export const AdditionalLinks = observer(() => {
    const classes = useAdditionalLinksStyles();

    return (
        <React.Fragment>
            <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.ViewPassportList}>
                <Grid item>
                    <Button
                        component={NavLink}
                        to={clientRoute.passports}
                        isActive={(match: Match, location: Location): boolean => {
                            if (!match) {
                                return false;
                            }
                            return location.pathname !== clientRoute.passportsNew;
                        }}
                        startIcon={<FormatListNumbered className={classes.buttonIcon} />}
                        variant="text"
                        color="secondary"
                    >
                        <FormattedMessage id="passport.passports" />
                    </Button>
                </Grid>
            </AuthorizationCheck>

            <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.ViewPassportRegistry}>
                <Grid item>
                    <Button
                        component={NavLink}
                        to={clientRoute.passportsNew}
                        startIcon={<FileIcon className={classes.buttonIcon} />}
                        variant="text"
                        color="secondary"
                    >
                        <FormattedMessage id="passport.passportsNew" />
                    </Button>
                </Grid>
            </AuthorizationCheck>
        </React.Fragment>
    );
});

export const AdditionalLinksDi = injectable(AdditionalLinksInj, () => {
    return <AdditionalLinks />;
});

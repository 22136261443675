import React from 'react';
import { Root as RootICS, RootStoreProps } from '@platform/ics-front-core';
import { AdditionalLinksDi } from './di/components/app-bar/AdditionalLinks';
import { AdditionalPagesDi } from './di/AdditionalPages';
import { RootStoreDi } from './di/store/RootStore';
import { RegFormSelectPageDi } from './di/pages/campaign-request/regform-select-page/RegFormSelect';
import { RequestStoreDi } from './di/store/RequestStore';
import { Router } from 'react-router-dom';
import { AdditionalTitleLinkDi } from './di/pages/campaign-request/campaign-request-page/AdditionalTitleLink';
import { PersonStoreDi } from './di/store/PersonStore';

export const Root = (props: RootStoreProps): JSX.Element => {
    return (
        <Router history={props.history}>
            <RootICS
                {...props}
                use={[
                    AdditionalPagesDi,
                    AdditionalLinksDi,
                    AdditionalTitleLinkDi,
                    RegFormSelectPageDi,
                    RootStoreDi,
                    RequestStoreDi,
                    PersonStoreDi
                ]}
            />
        </Router>
    );
};

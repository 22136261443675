import { action, observable } from 'mobx';
import { PassportRow } from './PassportListModel';
import { IdTitle, UserNameModel } from '@platform/ics-front-core';

export class PassportRowModel {
    @observable id: string = '';
    @observable number: string = '';
    @observable title: string = '';
    @observable orgFullNameKntp: string = '';
    @observable author = new UserNameModel();
    @observable campaign: IdTitle = {
        id: '',
        title: '',
    };
    @observable created: string = '';
    @observable state: string = '';

    @action.bound
    load(row: PassportRow) {
        Object.assign(this, row);
        return this;
    }
}

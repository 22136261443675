const campaign: Record<string, string | Record<string, string>> = {
    titleCampaignListPage: 'Подпрограммы',
    titleCreateCampaignPage: 'Создание подпрограммы',
    noCategoriesText: 'В данный момент нет активных категорий для подачи заявки',
    newCampaign: 'Новая подпрограмма',
    createCampaign: 'Создать подпрограмму',
    editCampaign: 'Редактирование подпрограммы',
    campaignRequests: 'Заявки подпрограммы',
    campaignSettings: 'Настройка подпрограммы',
    campaignDescription: 'Описание подпрограммы',
    campaignDescriptionEdit: 'Редактирование подпрограммы',
    campaignManagement: 'Управление подпрограммой',
    documentCategory: 'Категория документа',
    campaignManagementDelete: 'Вы действительно хотите удалить подпрограмму "{campaignName}"?',
    deleteCampaign: 'Удалить подпрограмму',
    campaignTitle: 'Управление подпрограммой {campaignName}',
    createRequestError:
        'Вами уже создана заявка по данной подпрограмме. Для просмотра и редактирования перейдите на вкладку Заявки',
};

export default campaign;

import React from 'react';
import { PassportRelatedListModel } from '../../../../models/passport/PassportRelatedListModel';
import {
    createStyles,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import {
    AutocompleteProps,
    ClearFilterButton,
    pickerProps,
    Select,
    textFieldProps,
    useStore,
} from '@platform/ics-front-core';
import { DatePicker } from '@material-ui/pickers';
import { SelectRegForm } from './SelectRegForm';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() =>
    createStyles({
        fullWith: {
            width: '100%',
        },
    }),
);

export type RelatedObjectFilters = {
    model: PassportRelatedListModel;
};

export const RelatedObjectFilters = observer(
    (props: RelatedObjectFilters): JSX.Element => {
        const { model } = props;
        const { intlStore: intl } = useStore();
        const classes = useStyles();

        const clearButtonHandler = (handler: (date: Date | null) => void): (() => void) => {
            return (): void => handler(null);
        };

        const { filtering } = model;

        const utilText = intl.formatMessage('common.until');
        const formText = intl.formatMessage('common.from');
        const allText = intl.formatMessage('common.all');

        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="common.filters" />
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container direction="column" spacing={6}>
                        <Grid item container spacing={6}>
                            <Grid item xs={4}>
                                <TextField
                                    {...textFieldProps}
                                    label={<FormattedMessage id="passport.relatedTable.number" />}
                                    placeholder={allText}
                                    value={filtering.number.value}
                                    onChange={filtering.number.onChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <SelectRegForm model={model} intl={intl} />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    multiple={true}
                                    values={filtering.state.values}
                                    label={<FormattedMessage id="campaignRequest.registryColumns.state" />}
                                    placeholder={allText}
                                    selectData={model.stateFilterData}
                                    onChange={filtering.state.onChange}
                                    textFieldProps={{ ...textFieldProps }}
                                    autoCompleteProps={{ ...AutocompleteProps }}
                                />
                            </Grid>
                            <Grid item xs={4} container justify="space-between">
                                <Grid item xs={6}>
                                    <DatePicker
                                        {...pickerProps}
                                        className={classes.fullWith}
                                        label={<FormattedMessage id="passport.relatedTable.created" />}
                                        placeholder={formText}
                                        value={filtering.created.value.from}
                                        onChange={filtering.created.onFromChange}
                                        {...(filtering.created.value.to && { maxDate: filtering.created.value.to })}
                                        InputProps={{
                                            endAdornment: (
                                                <ClearFilterButton
                                                    clear={clearButtonHandler(filtering.created.onFromChange)}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DatePicker
                                        {...pickerProps}
                                        className={classes.fullWith}
                                        placeholder={utilText}
                                        value={filtering.created.value.to}
                                        onChange={filtering.created.onToChange}
                                        {...(filtering.created.value.from && { minDate: filtering.created.value.from })}
                                        InputProps={{
                                            endAdornment: (
                                                <ClearFilterButton
                                                    clear={clearButtonHandler(filtering.created.onToChange)}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4} container justify="space-between">
                                <Grid item xs={6}>
                                    <DatePicker
                                        {...pickerProps}
                                        className={classes.fullWith}
                                        label={<FormattedMessage id="passport.relatedTable.sent" />}
                                        placeholder={formText}
                                        value={filtering.sent.value.from}
                                        onChange={filtering.sent.onFromChange}
                                        {...(filtering.sent.value.to && { maxDate: filtering.sent.value.to })}
                                        InputProps={{
                                            endAdornment: (
                                                <ClearFilterButton
                                                    clear={clearButtonHandler(filtering.sent.onFromChange)}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DatePicker
                                        {...pickerProps}
                                        className={classes.fullWith}
                                        placeholder={utilText}
                                        value={filtering.sent.value.to}
                                        onChange={filtering.sent.onToChange}
                                        {...(filtering.sent.value.from && { minDate: filtering.sent.value.from })}
                                        InputProps={{
                                            endAdornment: (
                                                <ClearFilterButton
                                                    clear={clearButtonHandler(filtering.sent.onToChange)}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    },
);

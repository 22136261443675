import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Box, Button, Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Close } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { IdTitle, useStore } from '@platform/ics-front-core';
import { RootStore } from '../../../di/store/RootStore';
import { AutocompleteInput } from '../../../components/AutocompleteInput';

export type AuthorChangeDialogProps = {
    passportId: string;
    open: boolean;
    onClose: () => void;
    reloadData: () => void;
};

export const AuthorChangeDialog = (props: AuthorChangeDialogProps): JSX.Element => {
    const { passportId, open, onClose, reloadData } = props;
    const { intlStore: intl, requestStore, passportStore } = useStore() as RootStore;

    const [userId, setUserId] = useState<string | undefined>('');
    const [validationStarted, setValidationStarted] = useState<boolean>(false);
    const [usersOptions, setUsersOptions] = useState<IdTitle[]>([]);
    const [usersOptionsLoading, setUsersOptionsLoading] = useState<boolean>(false);
    const [usersOptionsLoaded, setUsersOptionsLoaded] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);

    const loadUsersOptions = (): void => {
        if (!usersOptionsLoaded && !usersOptionsLoading) {
            setUsersOptionsLoading(true);
            requestStore
                .getUsersList()
                .then((options: IdTitle[]) => {
                    setUsersOptions(options);
                    setUsersOptionsLoaded(true);
                })
                .finally(() => {
                    setUsersOptionsLoading(false);
                });
        }
    };

    const errorUser = (): string => {
        if (validationStarted && !userId) {
            return intl.formatMessage('validation.required');
        }
        return '';
    };

    const enableValidation = (): void => {
        setValidationStarted(true);
    };

    const onUserChange = (event: ChangeEvent<{}>, value: IdTitle | null): void => {
        enableValidation();
        setUserId(value?.id);
    };

    const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        enableValidation();
        if (userId) {
            setIsSending(true);
            passportStore
                .changeOwner(passportId, userId)
                .then(() => {
                    reloadData();
                })
                .finally(() => {
                    setIsSending(false);
                });
            onClose();
        }
    };

    return (
        <Dialog maxWidth="xs" fullWidth open={open} scroll="body">
            <Box pt={4} pr={4}>
                <Grid container justify="flex-end">
                    <Grid item>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box pl={12} pr={12} pb={12}>
                <Grid container justify="center">
                    <Grid item>
                        <Typography variant="h5">
                            <Box fontWeight="fontWeightBold">
                                <FormattedMessage id="passport.dialog.changeOwnerTitle" />
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Box pt={8}>
                    <form noValidate onSubmit={onSubmit}>
                        <Grid container spacing={6} direction="column" justify="center">
                            <Grid item>
                                <Autocomplete
                                    onOpen={loadUsersOptions}
                                    onChange={onUserChange}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.title}
                                    options={usersOptions.slice()}
                                    loading={usersOptionsLoading}
                                    renderInput={AutocompleteInput({
                                        label: intl.formatMessage('passport.owner'),
                                        required: true,
                                        errorText: errorUser(),
                                        isLoading: usersOptionsLoading,
                                    })}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={isSending}
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    type="submit"
                                >
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={onClose} fullWidth size="large" variant="contained">
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Box>
        </Dialog>
    );
};

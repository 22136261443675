import { action, observable } from 'mobx';
import { PassportDTO, PassportRequestDTO, PassportStore } from '../../store/PassportStore';
import { clientRoute as clientRouteICS, IdTitle, UserNameModel } from '@platform/ics-front-core';
import {
    ComponentWithValidationMessage,
    FormApi,
    FormioSidebarStore,
    FormModel,
    validateEditPage,
    validateReadonlyPage as validateReadonlyPageFormio,
} from '@platform/formiojs-react';
import { WebForm } from 'formiojs/WebForm';
import { generatePath } from 'react-router-dom';
import { History } from 'history';
import { RootStore } from '../../di/store/RootStore';
import { clientRoute } from '../../clientRoute';

export class PassportModel {
    @observable private passportStore: PassportStore;
    @observable private formioSidebarStore: FormioSidebarStore;
    @observable private history: History;

    @observable id: string;
    @observable number = '';
    @observable campaign: IdTitle = { id: '', title: '' };
    @observable request: PassportRequestDTO = { id: '', title: '' };
    @observable orgFullNameKntp = '';
    @observable created = '';
    @observable modified = '';
    @observable author: UserNameModel = new UserNameModel();
    @observable formModel: FormModel;
    @observable contactsFormModel: FormModel;
    @observable state = '';

    @observable formApi?: FormApi;
    @observable isFormReady: boolean = false;
    @observable formErrors: ComponentWithValidationMessage[] = [];

    formName = 'passport';

    constructor(passportId: string, rootStore: RootStore) {
        this.formioSidebarStore = rootStore.formioSidebarStore;
        this.passportStore = rootStore.passportStore;
        this.history = rootStore.history;
        this.id = passportId;
        this.formModel = new FormModel(this.id);
        this.contactsFormModel = new FormModel(this.id);
    }

    @action.bound
    load(dto: PassportDTO): void {
        this.number = dto.number;
        this.campaign = dto.campaign;
        this.orgFullNameKntp = dto.orgFullNameKntp;
        this.created = dto.created;
        this.modified = dto.modified;
        this.author.load(dto.author);
        this.modified = dto.modified;
        this.formModel.load(dto.formInfo);
        this.contactsFormModel.load(dto.contactInfo);
        this.state = dto.state;
        this.request = dto.request;
    }

    @action.bound
    onFormChange(form: WebForm): void {
        this.formioSidebarStore.updateItemsVisibility(this.formName, form);
    }

    @action.bound
    onFormReady(form: FormApi): void {
        this.formApi = form;
        this.formioSidebarStore.initSidebarItems(this.formName, form.form);
        this.isFormReady = true;
    }

    @action.bound
    validateAfterReadonly(): void {
        validateEditPage(this.formApi, this.formioSidebarStore, this.formName).finally(() => {
            this.formErrors = [];
        });
    }

    @action.bound
    deletePassport(): Promise<void> {
        const { id, passportStore, campaign, history } = this;

        return passportStore.deletePassport(id).then(() => {
            this.isFormReady = false;
            history.push(generatePath(clientRouteICS.campaign, { id: campaign.id }));
        });
    }

    @action.bound
    goToPassportEditPageAndValidate(path: string): () => void {
        return (): void => {
            this.isFormReady = false;
            this.history.push(path);
        };
    }

    @action.bound
    editPassport(onSuccess?: () => void): void {
        this.validatePage(() => this.savePassport(onSuccess)).catch(() => {
            this.validateAfterReadonly();
        });
    }

    @action.bound
    savePassport(onSuccess?: () => void): Promise<void> {
        const { formApi, id, passportStore } = this;

        return passportStore.savePassportForm(id, formApi!.getSubmission()).then(() => {
            this.isFormReady = false;
            this.history.push(generatePath(clientRoute.passport, { id }));
            onSuccess && onSuccess();
        });
    }

    validatePage(onSuccess?: () => Promise<void>): Promise<void> {
        const setFormErrors = (errors: ComponentWithValidationMessage[]): void => {
            this.formErrors = errors;
        };

        return validateReadonlyPageFormio({
            setFormErrors,
            onSuccess,
            formApi: this.formApi,
            formioSidebarStore: this.formioSidebarStore,
            formName: this.formName,
        });
    }

    @action.bound
    transitionLifeCycle(transitionId: string, passportId: string): Promise<void> {
        return this.passportStore.transitionToNextLifeCycleStep(transitionId, passportId);
    }
}

import React from 'react';
import { observer } from 'mobx-react';
import { createStyles, Link, makeStyles } from '@material-ui/core';
import { generatePath, NavLink } from 'react-router-dom';
import { Preset, TTable, TTableRow } from '@platform/ttable';
import { CellProps } from 'react-table';
import { AuthorizationCheck, entities, useStore } from '@platform/ics-front-core';
import { PassportRow } from '../../../models/passport/PassportListModel';
import { clientRoute } from '../../../clientRoute';
import { RootStore } from '../../../di/store/RootStore';
import { permissions } from '../../../authSchemeConfig';

export type PassportRequestTableProps = {
    setRowActions: (row: PassportRow) => JSX.Element;
};

// todo импортировать из ядра useCampaignRequestTableStylesInj (переименовать так, чтобы было понятно, что это общие стили для реестров) и заменить usePassportRequestTableStyles
export const usePassportRequestTableStyles = makeStyles(() =>
    createStyles({
        link: {
            display: 'inline',
        },
    }),
);

export const PassportRequestTable = observer(
    (props: PassportRequestTableProps): JSX.Element => {
        const classes = usePassportRequestTableStyles();

        const { setRowActions } = props;
        const { passportStore, presetStore, intlStore, userStore, catalogStore } = useStore() as RootStore;

        const saveListPresets = <RowType extends TTableRow>(settings: Preset<RowType>[]): Promise<void> =>
            presetStore.saveListPresets(settings);

        return (
            <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.PassportRegistryAdministration}>
                {(allowed: boolean): JSX.Element => (
                    <TTable<PassportRow>
                        registryCode="ics-passports"
                        fetchData={passportStore.registry}
                        fetchColumns={passportStore.registryColumns}
                        presetsSetting={presetStore}
                        fetchCatalog={catalogStore.getCatalog}
                        fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                        upload={passportStore.upload}
                        lang={intlStore.locale}
                        cellFormatters={{
                            number: function campaignTitle(cellProps: CellProps<PassportRow>): JSX.Element {
                                return (
                                    <Link
                                        className={classes.link}
                                        component={NavLink}
                                        underline="always"
                                        to={generatePath(clientRoute.passport, {
                                            id: cellProps.row.original.id,
                                        })}
                                    >
                                        {cellProps.row.original.number}
                                    </Link>
                                );
                            },
                        }}
                        saveListPresets={saveListPresets}
                        fetchUserRoleList={userStore.userRoleList}
                        rowActions={setRowActions}
                        isAdmin={allowed}
                    />
                )}
            </AuthorizationCheck>
        );
    },
);

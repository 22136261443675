class ClientRoute {
    passports = '/passports';
    passportsNew = '/passports/new';
    passport = '/passports/:id';
    passportRequests = '/passports/:id/relatedObjects';
    passportEdit = '/passports/:id/edit';
    passportContactInfo = '/passports/:id/contactInfo';

    relatedObjectsRegForms = '/campaigns/:campaignId/regForms/:passportId';
}

export const clientRoute = new ClientRoute();

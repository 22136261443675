import { action, observable } from 'mobx';
import { Api, RegForm, RegFormDTO, DateUtils } from '@platform/ics-front-core';
import apiConfigs from '../../apiConfigs';

export class RelatedRegFormsListModel {
    @observable regFormsData: RegForm[] = [];

    private api: Api;

    constructor(api: Api, passportId: string) {
        this.api = api;
        this.getRegFormsData(passportId);
    }

    @action.bound
    getRegFormsData(passportId: string): void {
        this.api
            .client(apiConfigs.relatedObjectRegForms(passportId))
            .then((r) => r.data)
            .then((data) => data.map(this.mapDTO))
            .then((data) => (this.regFormsData = data));
    }

    @action.bound
    mapDTO(dto: RegFormDTO): RegForm {
        const regStart = new Date(dto.regStart);
        const regEnd = new Date(dto.regEnd);
        return {
            id: dto.id,
            title: dto.title,
            procedureTitle: dto.procedureTitle,
            regStart: DateUtils.isValidDate(regStart) ? regStart : undefined,
            regEnd: DateUtils.isValidDate(regEnd) ? regEnd : undefined,
            allowAfterDeadline: dto.allowAfterDeadline,
            reEnrollAllowed: dto.reEnrollAllowed,
            fileInstruction: dto.fileInstruction,
        };
    }
}

import React, { useEffect, useState } from 'react';
import { AdditionalTitleLinkInj, AdditionalTitleLinkProps, useStore } from '@platform/ics-front-core';
import { injectable } from 'react-magnetic-di';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import { clientRoute } from '../../../../clientRoute';
import { generatePath, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { PassportLinkInfoDTO } from '../../../store/RequestStore';
import { RootStore } from '../../../store/RootStore';

export const AdditionalTitleLink = (props: AdditionalTitleLinkProps): JSX.Element => {
    const { requestId } = props;
    const { requestStore } = useStore() as RootStore;
    const [passportLinkInfo, setPassportLnikInfo] = useState<PassportLinkInfoDTO | null>();

    useEffect(() => {
        requestStore.getPassportLinkInfo(requestId).then((linkInfo: PassportLinkInfoDTO) => {
            setPassportLnikInfo(linkInfo);
        });
    }, [requestId]);

    return (
        <React.Fragment>
            {passportLinkInfo && (
                <Grid item>
                    <Typography variant="h3" component="p">
                        <Box display="flex" flexWrap="wrap" component="span">
                            <FormattedMessage id="passport.requestLink" />
                            <Box pl={2} component="span">
                                <Link
                                    component={NavLink}
                                    underline="none"
                                    to={generatePath(clientRoute.passport, {
                                        id: passportLinkInfo.id,
                                    })}
                                >
                                    <Typography variant="h3" component="span">
                                        № {passportLinkInfo.title}
                                    </Typography>
                                </Link>
                            </Box>
                        </Box>
                    </Typography>
                </Grid>
            )}
        </React.Fragment>
    );
};

export const AdditionalTitleLinkDi = injectable(AdditionalTitleLinkInj, (props: AdditionalTitleLinkProps) => {
    return <AdditionalTitleLink {...props} />;
});

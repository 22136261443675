import React from 'react';
import { Container, Grid } from '@material-ui/core';
import {
    StickyWrapper,
    RequestActionsBar,
    CampaignEditButton,
    CampaignCancelButtonWithDialog,
    CampaignCancelButton,
    CampaignSaveButton,
    AuthorizationCheck,
    TotObjectTransitions,
    useStore,
    useReload,
} from '@platform/ics-front-core';
import { PassportModel } from '../../../../models/passport/PassportModel';
import { PassportDeleteButton } from './PassportDeleteButton';
import { generatePath, Route, Switch, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { observer } from 'mobx-react';
import { entities, permissions } from '../../../../authSchemeConfig';
import { RootStore } from '../../../../di/store/RootStore';

export type PassportControlPanelProps = {
    model: PassportModel;
    formIsChanged: boolean;
    reloadData: () => void;
};

export const PassportControlPanel = observer((props: PassportControlPanelProps) => {
    const { passportStore } = useStore() as RootStore;
    const { model, formIsChanged, reloadData } = props;
    const { goToPassportEditPageAndValidate, transitionLifeCycle, id, editPassport } = model;
    const [reloadKey, reloadIncrement] = useReload();
    const history = useHistory();

    const onCancelConfirm = (): void => {
        history.push(generatePath(clientRoute.passport, { id }));
    };

    const onSaveForm = (): void => {
        editPassport(reloadData);
    };

    const onTransitionChange = () => {
        reloadData();
        reloadIncrement();
    };

    return (
        <StickyWrapper mode="bottom">
            <RequestActionsBar>
                <Container maxWidth="lg">
                    <Grid alignItems="center" justify="space-between" container wrap="nowrap">
                        <Grid container item spacing={3} alignItems="center" xs={4} wrap="nowrap">
                            <AuthorizationCheck entityCode={entities.Passport} permCode={permissions.Passport.Edit}>
                                <Switch>
                                    <Route path={clientRoute.passport} exact>
                                        <AuthorizationCheck
                                            entityCode={entities.Passport}
                                            permCode={permissions.Passport.Delete}
                                        >
                                            <Grid item>
                                                <PassportDeleteButton model={model} />
                                            </Grid>
                                        </AuthorizationCheck>
                                        <Grid item>
                                            {id && (
                                                <CampaignEditButton
                                                    onEdit={goToPassportEditPageAndValidate(
                                                        generatePath(clientRoute.passportEdit, { id }),
                                                    )}
                                                />
                                            )}
                                        </Grid>
                                    </Route>
                                    <Route path={clientRoute.passportEdit} exact>
                                        <Grid item>
                                            <CampaignSaveButton onSubmit={onSaveForm} />
                                        </Grid>
                                        <Grid item>
                                            {formIsChanged ? (
                                                <CampaignCancelButtonWithDialog onConfirm={onCancelConfirm} />
                                            ) : (
                                                <CampaignCancelButton
                                                    path={generatePath(clientRoute.passport, { id })}
                                                />
                                            )}
                                        </Grid>
                                    </Route>
                                </Switch>
                            </AuthorizationCheck>
                        </Grid>

                        <Grid container item direction="row" alignItems="center" justify="flex-end" spacing={3}>
                            <Switch>
                                <Route path={clientRoute.passport} exact>
                                    <Grid item>
                                        <TotObjectTransitions
                                            key={reloadKey}
                                            objectId={id}
                                            getTransitions={passportStore.getTransitionPassport}
                                            updateObjectPage={onTransitionChange}
                                            lifeCycleTransition={transitionLifeCycle}
                                        />
                                    </Grid>
                                </Route>
                            </Switch>
                        </Grid>
                    </Grid>
                </Container>
            </RequestActionsBar>
        </StickyWrapper>
    );
});

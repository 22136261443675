import { PassportRelatedListModel } from '../../../../models/passport/PassportRelatedListModel';
import { observer } from 'mobx-react';
import { AutocompleteProps, Select, textFieldProps, IntlStore } from '@platform/ics-front-core';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export type SelectRegFormProps = {
    model: PassportRelatedListModel;
    intl: IntlStore;
};

export const SelectRegForm = observer(
    (props: SelectRegFormProps): JSX.Element => {
        const { model, intl } = props;
        const { filtering } = model;
        return (
            <Select
                multiple={true}
                textFieldProps={{ ...textFieldProps }}
                selectData={model.regFormFilterData}
                onChange={filtering.regForm.onChange}
                values={filtering.regForm.values}
                label={<FormattedMessage id="campaignRequest.registryColumns.regForm" />}
                placeholder={intl.formatMessage('common.all')}
                autoCompleteProps={{ ...AutocompleteProps }}
            />
        );
    },
);

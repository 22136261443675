const passport: Record<string, Record<string, string> | string> = {
    passports: 'My passports',
    passportsNew: 'Passports',
    owner: 'Owner',
    actions: {
        changeOwner: 'Change owner',
    },
    dialog: {
        changeOwnerTitle: 'Appoint a new owner',
        confirmDeletionInfoText: 'Are you sure you want to delete the passport №{number}?',
    },
    list: {
        number: 'Passport`s number',
        title: 'Passport`s title',
        owner: 'Сustomer KNTP',
        author: 'Author',
        campaign: 'Subroutine',
        created: 'Date of creation',
        state: 'State',
    },
    registryPagingInfo: 'Total {count, number} passports. Displayed from {from, number} to {to, number}.',
    registryPagingInfoIfNull: 'Total 0 passports. Displayed 0 passports',
    pageCard: {
        title: 'Request passport',
        requestKNTP: 'Request for the selection of KNTP',
        customerKNTP: 'Customer КНТП',
    },
    requestLink: 'Request passport',
    tabs: {
        info: 'Information about KNTP',
        relatedObjects: 'Related objects',
        contactInformation: 'Contact information',
    },
    requestQuestion:
        'To apply for grants, for changes in the passport or submission of reporting materials, you must click on the button [Create request]',
    relatedTable: {
        number: 'Request number',
        title: 'Request form',
        created: 'Date of creation',
        sent: 'Date of sent',
        quarter: 'Quarter',
        yearQuarter: 'Year',
        state: 'State',
    },
};

export default passport;

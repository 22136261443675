import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { Authentication } from '@platform/ics-front-core';
import { PassportsListPage } from './list-page/PassportsListPage';
import { observer } from 'mobx-react';
import { PassportPageRoutes } from './passport-page/PassportPageRoutes';
import { PassportsListPageNew } from './list-page/PassportsListPageNew';

export const PassportPages = observer(
    (): JSX.Element => {
        return (
            <Authentication>
                <Switch>
                    <Route path={clientRoute.passportsNew}>
                        <PassportsListPageNew />
                    </Route>
                    <Route path={clientRoute.passports} exact>
                        <PassportsListPage />
                    </Route>
                    <Route path={clientRoute.passport}>
                        <PassportPageRoutes />
                    </Route>
                </Switch>
            </Authentication>
        );
    },
);

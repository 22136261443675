import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { clientRoute } from '../../../clientRoute';
import { generatePath, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { Form, FormView } from '@platform/formiojs-react';
import { PassportModel } from '../../../models/passport/PassportModel';
import { PassportRelatedObjects } from './passport-related-objects/PassportRelatedObjects';
import { AuthorizationCheck, PageContentContainer, useModal, useStore } from '@platform/ics-front-core';
import { RootStore } from '../../../di/store/RootStore';
import { PassportPage } from './PassportPage';
import { Grid } from '@material-ui/core';
import { PassportHeader } from './PassportHeader';
import { AuthorChangeDialog } from './AuthorChangeDialog';
import { entities, permissions } from '../../../authSchemeConfig';

type RouteParams = {
    id: string;
};

export const PassportPageRoutes = observer(
    (): JSX.Element => {
        const { id } = useParams<RouteParams>();
        const [sidebarOpen, setSidebarOpen] = useState(true);
        const [formIsChanged, setFormIsChanged] = useState(false);
        const [open, setModalIsOpen, setModalIsClosed] = useModal();

        const rootStore = useStore() as RootStore;
        const [passportModel, setPassportModel] = useState<PassportModel>(() => new PassportModel(id, rootStore));
        const { intlStore, passportStore } = rootStore;
        const { locale } = intlStore;
        const { formModel, contactsFormModel, onFormReady, onFormChange } = passportModel;

        useEffect(() => {
            loadPassport();
        }, [passportStore, id, passportModel]);

        useEffect(() => {
            setFormIsChanged(false);
        }, [locale, passportModel]);

        const reloadPassportModel = () => {
            setPassportModel(new PassportModel(id, rootStore));
            loadPassport();
        };

        const loadPassport = () => {
            passportStore.getPassportDTO(id).then(passportModel.load);
            setFormIsChanged(false);
        };

        const createPassportPage = (pageContent: JSX.Element, isWithSideBar = false): JSX.Element => (
            <PassportPage
                passportModel={passportModel}
                locale={locale}
                isWithSideBar={isWithSideBar}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                formIsChanged={formIsChanged}
                setFormIsChanged={setFormIsChanged}
                reloadData={reloadPassportModel}
            >
                {pageContent}
            </PassportPage>
        );

        return (
            <PageContentContainer container direction="column" wrap="nowrap">
                <AuthorChangeDialog
                    passportId={id}
                    onClose={setModalIsClosed}
                    open={open}
                    reloadData={reloadPassportModel}
                />
                <Grid item>
                    <PassportHeader passportModel={passportModel} onChangeAuthor={setModalIsOpen} />
                </Grid>
                <Switch>
                    <Route path={[clientRoute.passport, clientRoute.passportEdit]} exact>
                        <Switch>
                            <Route path={clientRoute.passport} key={clientRoute.passport} exact>
                                {createPassportPage(
                                    <FormView locale={locale} form={formModel} onFormReady={onFormReady} />,
                                    true,
                                )}
                            </Route>
                            <Route path={clientRoute.passportEdit} key={clientRoute.passportEdit} exact>
                                <AuthorizationCheck entityCode={entities.Passport} permCode={permissions.Passport.Edit}>
                                    {(allowed) => {
                                        return (
                                            <React.Fragment>
                                                {!allowed && (
                                                    <Redirect to={generatePath(clientRoute.passport, { id })} />
                                                )}
                                            </React.Fragment>
                                        );
                                    }}
                                </AuthorizationCheck>
                                {createPassportPage(
                                    <Form
                                        form={formModel}
                                        onFormReady={onFormReady}
                                        onFormChange={onFormChange}
                                        setFormIsChanged={setFormIsChanged}
                                    />,
                                    true,
                                )}
                            </Route>
                        </Switch>
                    </Route>
                    <Route path={clientRoute.passportRequests} key={clientRoute.passportRequests} exact>
                        {createPassportPage(<PassportRelatedObjects passportId={id} />)}
                    </Route>
                    <Route path={clientRoute.passportContactInfo} key={clientRoute.passportContactInfo} exact>
                        {createPassportPage(
                            <FormView locale={locale} form={contactsFormModel} onFormReady={onFormReady} />,
                            true,
                        )}
                    </Route>
                </Switch>
            </PageContentContainer>
        );
    },
);

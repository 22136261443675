export default {
    startPageLinks: false,
    startPage: false,
    loginWithoutRegister: false,
    customLogo: {
        isEnabled: true,
        src: require(`../resources/images/logo.svg`),
        style: { width: '48px' },
    },
    customBackground: {
        isEnabled: true,
        src: require(`../resources/images/background.jpg`),
    },
    customSidebarIcon: {
        isEnabled: true,
        src: require(`../resources/images/icons/sidebar-icon.svg`),
    },
    helpIcon: false,
    notificationIcon: true,
    phoneNumber: false,
    startPageDefaultText: false,
    esiaLogin: true,
    showCreateRequestButton: false,
    showRequestsLamp: false,
    loginDialogClose: false,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: false,
};

import { Env, RootStoreProps } from '@platform/ics-front-core';
import { createBrowserHistory } from 'history';

const projectName = process.env.REACT_APP_PROJECT_NAME;

const env: Env = {
    apiUrl: process.env.REACT_APP_API_URL,
    projectName,
    totCopyright: process.env.REACT_APP_TOT_COPYRIGHT,
    totTel: process.env.REACT_APP_TOT_TEL,
    totMail: process.env.REACT_APP_TOT_MAIL,
    startPageText: process.env.REACT_APP_START_PAGE_TEXT,
    termsOfServiceLink: process.env.REACT_APP_TERMS_OF_SERVICE,
    yaMetrikaId: Number(process.env.REACT_APP_METRIKA_ID),
    pfDelay: Number(process.env.REACT_APP_PF_DELAY) || 30000,
};

let features: RootStoreProps['features'];
let ru = {};
let en = {};
let themeOverrides: RootStoreProps['themeOverrides'];
if (projectName) {
    features = require(`./features/${projectName}`).default;
    ru = require(`./resources/locales/${projectName}/ru`).default;
    en = require(`./resources/locales/${projectName}/en`).default;
    themeOverrides = require(`./themes/${projectName}/${projectName}`).default;
}

const locales: RootStoreProps['locales'] = { ru, en };

const history = createBrowserHistory();

export { env, features, locales, themeOverrides, history };

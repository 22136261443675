import { action } from 'mobx';
import { IdTitle, RequestStore as RequestStoreInj } from '@platform/ics-front-core';
import { injectable } from 'react-magnetic-di';
import apiConfigs from '../../apiConfigs';

export type PassportLinkInfoDTO = {
    id: string;
    title: string;
};

export class RequestStore extends RequestStoreInj {
    @action.bound
    createRelatedRequest(regFormId: string, passportId: string): Promise<string> {
        return this.api.client(apiConfigs.createRelatedRequest(passportId, regFormId)).then((r) => r.data);
    }

    @action.bound
    getUsersList(): Promise<IdTitle[]> {
        return this.api.client(apiConfigs.getUsersList()).then((r) => r.data);
    }

    @action.bound
    getPassportLinkInfo(requestId: string): Promise<PassportLinkInfoDTO> {
        return this.api.client(apiConfigs.getPassportLinkInfo(requestId)).then((r) => r.data);
    }
}

export const RequestStoreDi = injectable(RequestStoreInj, RequestStore);

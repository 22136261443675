import React from 'react';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Clear, ExpandMore } from '@material-ui/icons';
import { AutocompleteProps, pickerProps, Select, textFieldProps, useStore } from '@platform/ics-front-core';
import { PassportListModel } from '../../../../models/passport/PassportListModel';
import { observer } from 'mobx-react';
import { DatePicker } from '@material-ui/pickers';

export type PassportsListFilterPanelProps = {
    model: PassportListModel;
};

export const PassportsListFilterPanel = observer(
    (props: PassportsListFilterPanelProps): JSX.Element => {
        const { model } = props;
        const { intlStore: intl } = useStore();

        const clearButtonHandler = (handler: (date: Date | null) => void): (() => void) => {
            return (): void => handler(null);
        };

        const { filtering } = model;

        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="common.filters" />
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container direction="column" spacing={6}>
                        <Grid item container spacing={6}>
                            <Grid item xs={4}>
                                <TextField
                                    {...textFieldProps}
                                    label={<FormattedMessage id="passport.list.number" />}
                                    placeholder={intl.formatMessage('common.all')}
                                    value={filtering.number.value}
                                    onChange={filtering.number.onChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...textFieldProps}
                                    label={<FormattedMessage id="passport.list.title" />}
                                    placeholder={intl.formatMessage('common.all')}
                                    value={filtering.title.value}
                                    onChange={filtering.title.onChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...textFieldProps}
                                    label={<FormattedMessage id="passport.list.owner" />}
                                    placeholder={intl.formatMessage('common.all')}
                                    value={filtering.orgFullNameKntp.value}
                                    onChange={filtering.orgFullNameKntp.onChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    multiple={true}
                                    values={filtering.author.values}
                                    label={<FormattedMessage id="passport.list.author" />}
                                    selectData={model.authorFilterData}
                                    onChange={filtering.author.onChange}
                                    placeholder={intl.formatMessage('common.all')}
                                    textFieldProps={{ ...textFieldProps }}
                                    autoCompleteProps={{
                                        ...AutocompleteProps,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    multiple={true}
                                    values={filtering.campaign.values}
                                    label={<FormattedMessage id="passport.list.campaign" />}
                                    selectData={model.campaignFilterData}
                                    onChange={filtering.campaign.onChange}
                                    placeholder={intl.formatMessage('common.all')}
                                    textFieldProps={{ ...textFieldProps }}
                                    autoCompleteProps={{
                                        ...AutocompleteProps,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} container justify="space-between">
                                <Grid item xs={6}>
                                    <DatePicker
                                        {...pickerProps}
                                        label={<FormattedMessage id="passport.list.created" />}
                                        placeholder={intl.formatMessage('common.from')}
                                        value={filtering.created.value.from}
                                        onChange={filtering.created.onFromChange}
                                        {...(filtering.created.value.from && { maxDate: filtering.created.value.from })}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    onClick={(e): void => {
                                                        e.stopPropagation();
                                                        clearButtonHandler(filtering.created.onFromChange)();
                                                    }}
                                                >
                                                    <Clear />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DatePicker
                                        {...pickerProps}
                                        placeholder={intl.formatMessage('common.until')}
                                        value={filtering.created.value.to}
                                        onChange={filtering.created.onToChange}
                                        {...(filtering.created.value.to && { minDate: filtering.created.value.to })}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    onClick={(e): void => {
                                                        e.stopPropagation();
                                                        clearButtonHandler(filtering.created.onToChange)();
                                                    }}
                                                >
                                                    <Clear />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    multiple={true}
                                    textFieldProps={{ ...textFieldProps }}
                                    selectData={model.stateFilterData}
                                    onChange={filtering.state.onChange}
                                    values={filtering.state.values}
                                    label={<FormattedMessage id="passport.list.state" />}
                                    placeholder={intl.formatMessage('common.all')}
                                    autoCompleteProps={{ ...AutocompleteProps }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    },
);

import { RootStore as RootStoreInj, RootStoreProps } from '@platform/ics-front-core';
import { observable } from 'mobx';
import { PassportStore } from '../../store/PassportStore';
import { injectable } from 'react-magnetic-di';
import { RequestStore } from './RequestStore';

export class RootStore extends RootStoreInj {
    @observable requestStore: RequestStore;
    @observable passportStore: PassportStore;

    constructor(props: RootStoreProps) {
        super(props);

        this.requestStore = new RequestStore(this);
        this.passportStore = new PassportStore(this);
    }
}

export const RootStoreDi = injectable(RootStoreInj, RootStore);

import React from 'react';
import { clientRoute } from '../clientRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import { injectable } from 'react-magnetic-di';
import { AdditionalPagesInj } from '@platform/ics-front-core';
import { PassportPages } from '../pages/passport/PassportPages';
import { observer } from 'mobx-react';

export const AdditionalPages = observer(() => {
    return (
        <Switch>
            <Route path={clientRoute.passports}>
                <PassportPages />
            </Route>
            <Route>
                <Redirect to={clientRoute.passports} />
            </Route>
        </Switch>
    );
});

export const AdditionalPagesDi = injectable(AdditionalPagesInj, () => {
    return <AdditionalPages />;
});

import { PassportModel } from '../../../../models/passport/PassportModel';
import { ConfirmationDialog, useModal } from '@platform/ics-front-core';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import React from 'react';

export type PassportDeleteButtonProps = {
    model: PassportModel;
};

export const PassportDeleteButton = (props: PassportDeleteButtonProps): JSX.Element => {
    const { model } = props;
    const [open, setModalIsOpen, setModalIsClosed] = useModal();

    const onConfirm = (stopSending?: () => Promise<void>) => {
        return model.deletePassport().finally(() => {
            stopSending && stopSending();
            setModalIsClosed();
        });
    };

    return (
        <>
            <ConfirmationDialog
                id="delete-passport"
                title={<FormattedMessage id="common.confirmDeletion" />}
                message={
                    <FormattedMessage id="passport.dialog.confirmDeletionInfoText" values={{ number: model.number }} />
                }
                onCancel={setModalIsClosed}
                open={open}
                onConfirm={onConfirm}
                keepMounted
            />
            <Button size="small" variant="contained" color="primary" onClick={setModalIsOpen}>
                <FormattedMessage id="common.delete" />
            </Button>
        </>
    );
};

const campaign: Record<string, string | Record<string, string>> = {
    titleCampaignListPage: 'Subroutines',
    noCategoriesText: 'There are currently no active subroutines to apply for',
    newCampaign: 'New subroutine',
    titleCreateCampaignPage: 'Create subroutine',
    createCampaign: 'Create subroutine',
    editCampaign: 'Edit subroutine',
    campaignPeriod: 'Campaign period',
    campaignRequests: 'Campaign requests',
    campaignSettings: 'Campaign settings',
    campaignDescription: 'Campaign description',
    campaignDescriptionEdit: 'Campaign editing',
    campaignManagement: 'Subroutine management',
    documentCategory: 'Document category',
    campaignManagementDelete: 'Are you sure you want to delete the subroutine "{campaignName}"?',
    deleteCampaign: 'Delete subroutine',
    createRequestError:
        'You have already created an application for this subroutine. To view and edit, go to the Applications tab',
};

export default campaign;

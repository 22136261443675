import {
    Api,
    DateRangeFilter,
    GroupedIdTitle,
    IdTitle,
    InSetFilter,
    LikeFilter,
    RowsData,
    TableModel,
    TableQueryData,
    UserNameDTO,
    UserNameModel,
    IntlStore,
} from '@platform/ics-front-core';
import { action, observable } from 'mobx';
import { PassportRowModel } from './PassportRowModel';
import apiConfigs from '../../apiConfigs';
import downloadFile from 'js-file-download';

export interface PassportRowDTO {
    id: string;
    number: string;
    title?: string;
    orgFullNameKntp?: string;
    campaign?: IdTitle;
    author: UserNameDTO;
    created: string;
    state: string;
}

export interface PassportRow {
    id: string;
    number: string;
    title?: string;
    orgFullNameKntp?: string;
    campaign?: IdTitle;
    author: UserNameModel;
    created: string;
    state: string;
}

export type PassportFilter = {
    number: LikeFilter;
    title: LikeFilter;
    orgFullNameKntp: LikeFilter;
    author: InSetFilter;
    campaign: InSetFilter;
    created: DateRangeFilter;
    state: InSetFilter;
};

export class PassportListModel extends TableModel<PassportRowModel, PassportFilter, {}> {
    @observable api: Api;
    @observable intlStore: IntlStore;

    @observable campaignFilterData: IdTitle[];
    @observable stateFilterData: GroupedIdTitle;
    @observable authorFilterData: IdTitle[];

    constructor(api: Api, intlStore: IntlStore) {
        const filter = {
            number: new LikeFilter(),
            title: new LikeFilter(),
            orgFullNameKntp: new LikeFilter(),
            author: new InSetFilter(),
            campaign: new InSetFilter(),
            created: new DateRangeFilter(),
            state: new InSetFilter(),
        };
        super(filter, {});

        this.api = api;
        this.intlStore = intlStore;

        this.campaignFilterData = [];
        this.stateFilterData = {};
        this.authorFilterData = [];
    }

    @action.bound
    loadFilterData(): void {
        this.api
            .client(apiConfigs.passportListFilterData())
            .then((r) => r.data)
            .then((filters) => {
                const author = new UserNameModel();

                this.authorFilterData = filters.authors.map((o: UserNameDTO) => {
                    author.load(o);
                    return author.asIdTitle;
                });
                this.campaignFilterData = filters.campaigns;
                this.stateFilterData = filters.states;
            });
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<PassportRowModel>> {
        return this.api
            .client(apiConfigs.passportsList(queryData))
            .then((r) => r.data)
            .then(({ rows, count }) => ({ rows: rows.map(this.mapDtoToRow), count }));
    }

    @action.bound
    mapDtoToRow(dto: PassportRowDTO): PassportRowModel {
        const rowModel = new PassportRowModel();
        const author = new UserNameModel().load(dto.author);
        rowModel.load({ ...dto, author });
        return rowModel;
    }

    @action.bound
    exportListXls(): void {
        const filename = this.intlStore.formatMessage('passport.passports');
        this.api
            .client(apiConfigs.passportsXls(this.queryData))
            .then((r) => r.data)
            .then((f) => downloadFile(f, `${filename}.xls`, 'application/vnd.ms-excel'));
    }
}

import React from 'react';
import { observer } from 'mobx-react';
import { PassportModel } from '../../../models/passport/PassportModel';
// todo переименовать CampaignRequestStyledTab в StyledTabInj в ядре
import { CampaignRequestStyledTab as StyledTab, tabsProps, useTabStylesInj as useTabStyles, RoutedTabs } from '@platform/ics-front-core';
import { generatePath, NavLink } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { FormattedMessage } from 'react-intl';
import { TabsActions } from '@material-ui/core';

export type PassportTabsProps = {
    passportModel: PassportModel;
    tabsActions: React.Ref<TabsActions>;
};

const settings = [
    {
        tab: 0,
        path: clientRoute.passport,
        exact: true,
    },
    {
        tab: 0,
        path: clientRoute.passportEdit,
        exact: true,
    },
    {
        tab: 1,
        path: clientRoute.passportRequests,
        exact: true,
    },
    {
        tab: 2,
        path: clientRoute.passportContactInfo,
        exact: true,
    },
];

export const PassportTabs = observer(
    (props: PassportTabsProps): JSX.Element => {
        const { passportModel, tabsActions } = props;
        const { id } = passportModel;
        const classes = useTabStyles();

        return (
            <RoutedTabs tabsProps={{ ...tabsProps, classes }} settings={settings} customTabsActions={tabsActions}>
                <StyledTab
                    value={0}
                    label={<FormattedMessage id="passport.tabs.info" />}
                    component={NavLink}
                    to={generatePath(clientRoute.passport, { id })}
                />
                <StyledTab
                    value={1}
                    label={<FormattedMessage id="passport.tabs.relatedObjects" />}
                    component={NavLink}
                    to={generatePath(clientRoute.passportRequests, { id })}
                />
                <StyledTab
                    value={2}
                    label={<FormattedMessage id="passport.tabs.contactInformation" />}
                    component={NavLink}
                    to={generatePath(clientRoute.passportContactInfo, { id })}
                />
            </RoutedTabs>
        );
    },
);

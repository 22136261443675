import React from 'react';
import { PassportModel } from '../../../models/passport/PassportModel';
import { Box, Button, Container, createStyles, Grid, Link, makeStyles, Typography } from '@material-ui/core';
// todo переименовать RequestStateBoxInj в StateBoxInj в ядре
import { AuthorizationCheck, clientRoute, RequestStateBoxInj as StateBox, TotObjectHeader } from '@platform/ics-front-core';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { generatePath, NavLink } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import { entities, permissions } from '../../../authSchemeConfig';

const useStyles = makeStyles((theme) =>
    createStyles({
        lightText: {
            color: theme.palette.primary.light,
        },
    }),
);

export type PassportHeaderProps = {
    passportModel: PassportModel;
    onChangeAuthor: () => void;
};

export const PassportHeader = observer(
    (props: PassportHeaderProps): JSX.Element => {
        const { passportModel, onChangeAuthor } = props;
        const { number, campaign, state, request, orgFullNameKntp, author } = passportModel;
        const requestId = request.id;
        const classes = useStyles();

        return (
            <TotObjectHeader>
                <Container maxWidth="lg">
                    <Grid container spacing={10} alignItems="flex-start">
                        <Grid item xs={8}>
                            <Grid container spacing={3} direction="column">
                                <Grid item>
                                    <Typography variant="h1">
                                        <Box fontWeight={800}>
                                            <FormattedMessage id="passport.pageCard.title" /> № {number}
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {requestId && (
                                        <Typography variant="h3" component="p">
                                            <Box display="flex" flexWrap="wrap" component="span">
                                                <FormattedMessage id="passport.pageCard.requestKNTP" />
                                                <Box pl={2} component="span">
                                                    <Link
                                                        component={NavLink}
                                                        underline="none"
                                                        to={generatePath(clientRoute.request, {
                                                            id: requestId,
                                                        })}
                                                    >
                                                        <Typography variant="h3" component="span">
                                                            № {request.title}
                                                        </Typography>
                                                    </Link>
                                                </Box>
                                            </Box>
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Typography variant="h3" component="p">
                                        {campaign.title}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1" component="p">
                                        <Box display="flex" flexWrap="wrap" component="span">
                                            <span className={classes.lightText}>
                                                <FormattedMessage id="passport.pageCard.customerKNTP" />:
                                            </span>
                                            <Box fontWeight={600} pl={2} component="span">
                                                {orgFullNameKntp ? orgFullNameKntp : '-'}
                                            </Box>
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid
                                container
                                spacing={3}
                                direction="column"
                                wrap="nowrap"
                                justify="center"
                                style={{ height: '100%' }}
                            >
                                <Grid item>
                                    <Box>
                                        <Typography variant="body2">
                                            <FormattedMessage id="common.State" />
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <StateBox>
                                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                            {state}
                                        </Typography>
                                    </StateBox>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        <FormattedMessage id="passport.owner" />: {author.name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <AuthorizationCheck
                                        entityCode={entities.Passport}
                                        permCode={permissions.Passport.UpdateAuthor}
                                    >
                                        <Button
                                            variant="text"
                                            color="primary"
                                            size="small"
                                            startIcon={<Edit />}
                                            onClick={onChangeAuthor}
                                        >
                                            <Typography variant="body2" style={{ textTransform: 'none' }}>
                                                <FormattedMessage id="passport.actions.changeOwner" />
                                            </Typography>
                                        </Button>
                                    </AuthorizationCheck>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </TotObjectHeader>
        );
    },
);

import React, { ReactNode } from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import { ReactComponent as DotMenu } from '../../../resources/images/icons/dot-menu.svg';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { observer } from 'mobx-react';
import { PassportRow } from '../../../models/passport/PassportListModel';
import { ActionMenuItem, MenuButton, AuthorizationCheck } from '@platform/ics-front-core';
import { entities, permissions } from '../../../authSchemeConfig';

export type CampaignRequestActionsBtnsProps = {
    passportRow: PassportRow;
};

export const PassportActionsBtns = observer(
    (props: CampaignRequestActionsBtnsProps): JSX.Element => {
        const { passportRow } = props;

        const renderActionItems = (passportRow: PassportRow): (() => ReactNode[]) => {
            const { id } = passportRow;

            return (): ReactNode[] => [
                id && (
                    <AuthorizationCheck
                        entityCode={entities.Passport}
                        entityId={id}
                        permCode={permissions.Passport.Edit}
                    >
                        <ActionMenuItem messageId="common.edit" path={generatePath(clientRoute.passportEdit, { id })} />
                    </AuthorizationCheck>
                ),
            ];
        };

        const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
            return (
                <IconButton onClick={onClick}>
                    <SvgIcon>
                        <DotMenu />
                    </SvgIcon>
                </IconButton>
            );
        };

        return (
            <MenuButton
                disablePortal={true}
                renderButton={renderActionsButton}
                renderMenuItems={renderActionItems(passportRow)}
            />
        );
    },
);

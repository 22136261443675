import React, { useEffect, useState } from 'react';
import { generatePath, Redirect, Route, useHistory, useParams } from 'react-router-dom';
import { clientRoute as newClientRoutes } from '../../../../clientRoute';
import {
    clientRoute,
    RegFormListModel,
    RegFormSelectPage as RegFormSelectPageInj,
    RegFormSelect,
    useStore,
    AuthorizationCheck,
} from '@platform/ics-front-core';
import { injectable } from 'react-magnetic-di';
import { observer } from 'mobx-react';
import { RelatedRegFormsListModel } from '../../../../models/passport/RelatedRegFormsListModel';
import { entities, permissions } from '../../../../authSchemeConfig';
import { RootStore } from '../../../store/RootStore';

type RouteParams = {
    campaignId: string;
    passportId: string;
};

export const RegFormSelectPage = observer(
    (): JSX.Element => {
        const { api, requestStore } = useStore() as RootStore;
        const { campaignId, passportId } = useParams<RouteParams>();
        const history = useHistory();

        const [regFormListModel, setRegFormListModel] = useState<RegFormListModel | null>();

        useEffect(() => {
            const model = (passportId
                ? new RelatedRegFormsListModel(api, passportId)
                : new RegFormListModel(api, campaignId)) as RegFormListModel;
            setRegFormListModel(model);
        }, [api, campaignId, passportId]);

        const createRequest = (regFormId: string): (() => Promise<void>) => {
            const request = passportId ? requestStore.createRelatedRequest : requestStore.createRequest;

            return (): Promise<void> => {
                return request(regFormId, passportId).then((id: string) => {
                    history.push(generatePath(clientRoute.requestEdit, { campaignId, regFormId, id }));
                });
            };
        };

        if (!regFormListModel) {
            return <React.Fragment></React.Fragment>;
        }

        const regForm = (): JSX.Element => (
            <RegFormSelect regFormListModel={regFormListModel} createRequest={createRequest} />
        );

        return (
            <>
                {passportId ? (
                    <AuthorizationCheck
                        entityCode={entities.Passport}
                        permCode={permissions.Passport.EditRelatedRequests}
                    >
                        {(allowed) => {
                            return allowed ? (
                                regForm()
                            ) : (
                                <Redirect to={generatePath(clientRoute.campaign, { id: campaignId })} />
                            );
                        }}
                    </AuthorizationCheck>
                ) : (
                    regForm()
                )}
            </>
        );
    },
);

export const RegFormSelectRoutes = observer(
    (): JSX.Element => {
        return (
            <Route path={[newClientRoutes.relatedObjectsRegForms, clientRoute.regFormSelect]} exact>
                <RegFormSelectPage />
            </Route>
        );
    },
);

export const RegFormSelectPageDi = injectable(RegFormSelectPageInj, () => {
    return <RegFormSelectRoutes />;
});

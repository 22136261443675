import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    createStyles,
    Grid,
    LinearProgress,
    Link,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { BreadcrumbsComponent, breadCrumbsSettings, TotTablePagination, useStore } from '@platform/ics-front-core';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { PassportListModel } from '../../../models/passport/PassportListModel';
import { GetApp } from '@material-ui/icons';
import { ReactComponent as ClearFilter } from '../../../resources/images/icons/clear-filter.svg';
import { PassportsListFilterPanel } from './components/PassportsListFilterPanel';
import { observer } from 'mobx-react';
import { PassportActionsBtns } from './PassportActionsBtns';
import { makeStyles } from '@material-ui/core/styles';

const usePassportsListPageStyles = makeStyles(() =>
    createStyles({
        tableBody: {
            overflowX: 'auto',
        },
        tableContainerWrapper: {
            width: '100%',
        },
    }),
);

export const PassportsListPage = observer(() => {
    const { api, intlStore } = useStore();
    const [model] = useState(() => new PassportListModel(api, intlStore));

    useEffect(() => {
        model.loadFilterData();
    }, [model]);

    const onChangePage = useCallback(
        (e: unknown, newPage: number): void => {
            const root = document.getElementById('root') as HTMLDivElement;
            root.scrollTop = 0;
            model.onChangePage(e, newPage);
        },
        [model],
    );

    const exportListXls = useCallback((): void => {
        model.exportListXls();
    }, [model]);

    const handleClickClearFilterButton = useCallback(() => {
        model.clearFilters();
    }, [model]);

    const classes = usePassportsListPageStyles();

    return (
        <React.Fragment>
            <Container maxWidth="lg">
                <Box pt={5.5} pb={15}>
                    <Box pb={5}>
                        <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} />
                    </Box>

                    <Grid container direction="column" spacing={10}>
                        <Grid item container direction="row" justify="space-between">
                            <Grid item xs={6}>
                                <Typography variant="h1">
                                    <FormattedMessage id="passport.passports" />
                                </Typography>
                            </Grid>
                            <Grid item xs={6} container spacing={2} justify="flex-end">
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        startIcon={
                                            <SvgIcon>
                                                <ClearFilter />
                                            </SvgIcon>
                                        }
                                        onClick={handleClickClearFilterButton}
                                    >
                                        <FormattedMessage id="common.resetFilters" />
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        startIcon={<GetApp />}
                                        onClick={exportListXls}
                                    >
                                        <FormattedMessage id="common.export" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <PassportsListFilterPanel model={model} />
                        </Grid>
                        <Grid item className={classes.tableContainerWrapper}>
                            <TableContainer component={Paper}>
                                {model.isLoading && <LinearProgress />}
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="passport.list.number" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="passport.list.title" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="passport.list.owner" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="passport.list.author" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="passport.list.campaign" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="passport.list.created" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="passport.list.state" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableBody}>
                                        {model.rows.map((r) => (
                                            <TableRow key={r.id} hover>
                                                <TableCell>
                                                    <Link
                                                        component={NavLink}
                                                        underline="none"
                                                        to={generatePath(clientRoute.passport, { id: r.id })}
                                                    >
                                                        {r.number}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{r.title}</TableCell>
                                                <TableCell>{r.orgFullNameKntp}</TableCell>
                                                <TableCell>{r.author.name}</TableCell>
                                                <TableCell>{r.campaign.title}</TableCell>
                                                <TableCell>
                                                    <FormattedDate value={r.created} />
                                                </TableCell>
                                                <TableCell>{r.state}</TableCell>

                                                <TableCell>
                                                    <PassportActionsBtns passportRow={r} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={12}>
                                                <TotTablePagination
                                                    count={model.rowsCount}
                                                    page={model.pageNumber}
                                                    onChangePage={onChangePage}
                                                    onChangeRowsPerPage={model.onChangePageSize}
                                                    rowsPerPage={model.pageSize}
                                                    rowsPerPageOptions={model.pageSizeOptions}
                                                    pagingInfoMessageId="passport.registryPagingInfo"
                                                    pagingInfoMessageIdIfNull="passport.registryPagingInfoIfNull"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
});

import { AxiosRequestConfig } from 'axios';
import { TableQueryData } from '@platform/ics-front-core';
import { FullSubmission } from '@platform/formiojs-react';
import { TTableQueryData } from '@platform/ttable';
import { PassportRow } from './models/passport/PassportListModel';

class ApiConfigs {
    getCatalogFilters: (institutes: string[]) => AxiosRequestConfig = (institutes) => {
        return {
            url: `/requests/catalogFilters`,
            method: 'PUT',
            data: institutes,
        };
    };

    passportsList: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/mcx/passports',
            method: 'PUT',
            data: queryData,
        };
    };

    passportsXls: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/mcx/passportsXls',
            method: 'PUT',
            responseType: 'blob',
            data: queryData,
        };
    };

    passportListFilterData: () => AxiosRequestConfig = () => {
        return {
            url: '/mcx/passports/filters',
            method: 'GET',
        };
    };

    getPassport: (passportId: string) => AxiosRequestConfig = (passportId) => {
        return {
            url: `/mcx/passport/${passportId}`,
            method: 'GET',
        };
    };

    getPassportLinkInfo: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/mcx/request/${requestId}/passport`,
            method: 'GET',
        };
    };

    updatePassport: (passportId: string, submission: FullSubmission) => AxiosRequestConfig = (
        passportId,
        submission,
    ) => {
        return {
            url: `/mcx/passport/${passportId}`,
            method: 'PUT',
            data: submission,
        };
    };

    deletePassport: (passportId: string) => AxiosRequestConfig = (passportId) => {
        return {
            url: `/mcx/passport/${passportId}`,
            method: 'DELETE',
        };
    };

    relatedObjectList: (queryData: TableQueryData, passportId: string) => AxiosRequestConfig = (
        queryData,
        passportId,
    ) => {
        return {
            url: `/mcx/passport/${passportId}/requests`,
            method: 'PUT',
            data: queryData,
        };
    };

    relatedObjectRegForms: (passportId: string) => AxiosRequestConfig = (passportId) => {
        return {
            url: `/mcx/passport/${passportId}/regForms`,
            method: 'GET',
        };
    };

    createRelatedRequest: (passportId: string, regFormId: string) => AxiosRequestConfig = (passportId, regFormId) => {
        return {
            url: `/mcx/passport/${passportId}/request?regFormId=${regFormId}`,
            method: 'POST',
        };
    };

    regFormsFilterData: (passportId: string) => AxiosRequestConfig = (passportId) => {
        return {
            url: `/mcx/select/regForms/passport/${passportId}`,
            method: 'GET',
        };
    };

    changePassportOwner: (passportId: string, userId: string) => AxiosRequestConfig = (passportId, userId) => {
        return {
            url: `/mcx/passport/${passportId}/author`,
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(userId),
        };
    };

    getUsersList: () => AxiosRequestConfig = () => {
        return {
            url: '/select/users/v2?role=User',
            method: 'GET',
        };
    };

    transitionPassport: (passportId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/mcx/passport/${requestId}/transitions`,
            method: 'GET',
        };
    };

    transitionToNextLifeCycleStep: (transitionId: string, passportId: string) => AxiosRequestConfig = (
        transitionId,
        passportId,
    ) => {
        return {
            url: `/mcx/passport/${passportId}/changeState?transition=${transitionId}`,
            method: 'PUT',
        };
    };

    registryPassports: (registryCode: string, queryData: TTableQueryData<PassportRow>) => AxiosRequestConfig = (
        registryCode,
        queryData,
    ) => {
        return {
            url: '/mcx/registry/passport/table',
            method: 'PUT',
            params: {
                code: registryCode,
            },
            data: queryData,
        };
    };

    registryPassportsColumns: (registryCode: string) => AxiosRequestConfig = (registryCode) => {
        return {
            url: '/mcx/registry/passport/columns',
            method: 'GET',
            params: {
                code: registryCode,
            },
        };
    };

    uploadPassports: (tableQueryData: TTableQueryData<PassportRow>) => AxiosRequestConfig = (tableQueryData) => ({
        url: '/mcx/registry/passport/export',
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });
}

export default new ApiConfigs();

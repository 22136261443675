import React, { useCallback, useEffect, useState } from 'react';
import {
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    Link,
    Grid,
    Button,
    Box,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
    TotTablePagination,
    useStore,
    clientRoute as clientRouteICS,
    AuthorizationCheck,
} from '@platform/ics-front-core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import { PassportRelatedListModel } from '../../../../models/passport/PassportRelatedListModel';
import { NavLink } from 'react-router-dom';
import { RootStore } from '../../../../di/store/RootStore';
import { generatePath } from 'react-router-dom';
import { RelatedObjectFilters } from './RelatedObjectFilters';

export type PassportRelatedObjectsProps = {
    passportId: string;
};

export const PassportRelatedObjects = observer(
    (props: PassportRelatedObjectsProps): JSX.Element => {
        const { passportId } = props;
        const { api } = useStore() as RootStore;
        const [model] = useState(() => new PassportRelatedListModel(api, passportId));

        const { sorting } = model;

        useEffect(() => {
            model.getFilterData(model.passportId);
        }, [model]);

        const onChangePage = useCallback(
            (e: unknown, newPage: number): void => {
                const root = document.getElementById('root') as HTMLDivElement;
                root.scrollTop = 0;
                model.onChangePage(e, newPage);
            },
            [model],
        );

        return (
            <>
                <Box mb={4}>
                    <Grid item>
                        <RelatedObjectFilters model={model} />
                    </Grid>
                </Box>
                <TableContainer component={Paper}>
                    {model.isLoading && <LinearProgress />}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={sorting.number.isActive}
                                        direction={sorting.number.direction}
                                        onClick={model.changeSorting(sorting.number)}
                                        IconComponent={ExpandMoreIcon}
                                    >
                                        <Typography>
                                            <FormattedMessage id="passport.relatedTable.number" />
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sorting.regForm.isActive}
                                        direction={sorting.regForm.direction}
                                        onClick={model.changeSorting(sorting.regForm)}
                                        IconComponent={ExpandMoreIcon}
                                    >
                                        <Typography>
                                            <FormattedMessage id="passport.relatedTable.title" />
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sorting.created.isActive}
                                        direction={sorting.created.direction}
                                        onClick={model.changeSorting(sorting.created)}
                                        IconComponent={ExpandMoreIcon}
                                    >
                                        <Typography>
                                            <FormattedMessage id="passport.relatedTable.created" />
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sorting.sent.isActive}
                                        direction={sorting.sent.direction}
                                        onClick={model.changeSorting(sorting.sent)}
                                        IconComponent={ExpandMoreIcon}
                                    >
                                        <Typography>
                                            <FormattedMessage id="passport.relatedTable.sent" />
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="passport.relatedTable.quarter" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="passport.relatedTable.yearQuarter" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="passport.relatedTable.state" />
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {model.rows.map((r) => {
                                const { sent, created, id } = r;

                                return (
                                    <TableRow key={id} hover>
                                        <TableCell>
                                            <Link
                                                component={NavLink}
                                                to={generatePath(clientRouteICS.request, { id: r.id })}
                                            >
                                                {r.number || <FormattedMessage id="campaignRequest.withoutNumber" />}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{r.regFormTitle}</TableCell>
                                        <TableCell>{created ? <FormattedDate value={created} /> : '-'}</TableCell>
                                        <TableCell>{sent ? <FormattedDate value={sent} /> : '-'}</TableCell>
                                        <TableCell>{r.quarter || '-'}</TableCell>
                                        <TableCell>
                                            {r.yearQuarter ? new Date(r.yearQuarter).getFullYear() : '-'}
                                        </TableCell>
                                        <TableCell>{r.state}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={12}>
                                    <TotTablePagination
                                        count={model.rowsCount}
                                        page={model.pageNumber}
                                        onChangePage={onChangePage}
                                        onChangeRowsPerPage={model.onChangePageSize}
                                        rowsPerPage={model.pageSize}
                                        rowsPerPageOptions={model.pageSizeOptions}
                                        pagingInfoMessageId="campaignRequest.registryPagingInfo"
                                        pagingInfoMessageIdIfNull="campaignRequest.registryPagingInfoIfNull"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </>
        );
    },
);

const campaignRequest: Record<string, Record<string, string> | string> = {
    registryColumns: {
        direction: 'Направление',
        institute: 'Институт',
        department: 'Кафедра',
        campaign: 'Подпрограмма',
    },
};

export default campaignRequest;

import React from 'react';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';

export type AutocompleteInputProps = {
    label: string;
    required: boolean;
    errorText: string;
    isLoading: boolean;
};

export const AutocompleteInput = (
    props: AutocompleteInputProps,
): ((params: AutocompleteRenderInputParams) => JSX.Element) => (params: AutocompleteRenderInputParams): JSX.Element => {
    const { label, required, errorText, isLoading } = props;

    return (
        <TextField
            {...params}
            required={required}
            label={label}
            variant="outlined"
            error={!!errorText}
            helperText={errorText}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <React.Fragment>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                ),
            }}
        />
    );
};

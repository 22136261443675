import { action, computed, observable } from 'mobx';
import {
    Api,
    clientRoute,
    handleAxiosErrorByResponseStatus,
    IdTitle,
    TransitionsDTO,
    UserNameDTO,
} from '@platform/ics-front-core';
import { History } from 'history';
import apiConfigs from '../apiConfigs';
import { FormDTO, FullSubmission } from '@platform/formiojs-react';
import { RootStore } from '../di/store/RootStore';
import { ColumnData, RowsData, TTableQueryData, TTableRow } from '@platform/ttable';
import fileDownload from 'js-file-download';
import { PassportRow } from '../models/passport/PassportListModel';

export interface PassportRequestDTO {
    id: string;
    title: string;
}

export interface PassportDTO {
    id: string;
    number: string;
    campaign: IdTitle;
    request: PassportRequestDTO;
    orgFullNameKntp: string;
    created: string;
    modified: string;
    author: UserNameDTO;
    formInfo: FormDTO;
    contactInfo: FormDTO;
    state: string;
}

export class PassportStore {
    @observable protected rootStore: RootStore;
    @observable protected api: Api;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    getPassportDTO(passportId: string): Promise<PassportDTO> {
        return this.api
            .client(apiConfigs.getPassport(passportId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    changeOwner(passportId: string, userId: string): Promise<void> {
        return this.api.client(apiConfigs.changePassportOwner(passportId, userId)).then((r) => r.data);
    }

    @action.bound
    deletePassport(passportId: string): Promise<void> {
        return this.api.client(apiConfigs.deletePassport(passportId)).then((r) => r.data);
    }

    @action.bound
    savePassportForm(passportId: string, submission: FullSubmission): Promise<void> {
        return this.api.client(apiConfigs.updatePassport(passportId, submission)).then((r) => r.data);
    }

    @action.bound
    getTransitionPassport(passportId: string): Promise<TransitionsDTO> {
        return this.api.client(apiConfigs.transitionPassport(passportId)).then((r) => r.data);
    }

    @action.bound
    transitionToNextLifeCycleStep(transitionId: string, passportId: string): Promise<void> {
        return this.api.client(apiConfigs.transitionToNextLifeCycleStep(transitionId, passportId)).then((r) => r.data);
    }

    @action.bound
    registry(registryCode: string, query: TTableQueryData<PassportRow>): Promise<RowsData<PassportRow>> {
        return this.api.client(apiConfigs.registryPassports(registryCode, query)).then((r) => r.data);
    }

    @action.bound
    registryColumns(registryCode: string): Promise<ColumnData<PassportRow>[]> {
        return this.api.client(apiConfigs.registryPassportsColumns(registryCode)).then((r) => r.data);
    }

    @action.bound
    async upload<RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.uploadPassports(tableQueryData));
            const filename = this.rootStore.intlStore.formatMessage('homePage.headerLinks.requestsNew');
            await fileDownload(data, `${filename}.xlsx`);
        } catch (error) {
            console.error(error);
        }
    }
}

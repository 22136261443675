import { injectable } from 'react-magnetic-di';
import { clientRoute as mcxClientRoute } from '../../clientRoute';
import { clientRoute, PersonStore as PersonStoreInj } from '@platform/ics-front-core';
import { computed } from 'mobx';

//TODO перейти на подход ядра после выполнения задачи ICS-1003
export const loginRedirectByRole: { [role: string]: string } = {
    User: mcxClientRoute.passports,
    Employee: mcxClientRoute.passportsNew,
    SeniorEmployee: mcxClientRoute.passportsNew,
    Moderator: mcxClientRoute.passportsNew,
    Ministry: mcxClientRoute.passportsNew,
};
//TODO перейти на подход ядра после выполнения задачи ICS-1003
export const rolePriorities: { [role: string]: number } = {
    User: 0,
    Employee: 1,
    SeniorEmployee: 2,
    Moderator: 3,
    Ministry: 4,
};

export const getRouteByRoles = (roles: string[]): string => {
    const rolesLength = roles.length;

    let redirect = '';
    let priority = 0;
    for (let index = 0; index < rolesLength; index++) {
        const role = roles[index];
        const rolePriority = rolePriorities[role];
        if ((rolePriority || rolePriority === 0) && rolePriority >= priority) {
            priority = rolePriority;
            redirect = loginRedirectByRole[role];
        }
    }

    if (redirect) {
        return redirect;
    }

    return clientRoute.campaigns;
};

export class PersonStore extends PersonStoreInj {
    @computed
    get redirectPath(): string {
        return getRouteByRoles(this.roles);
    }
}

export const PersonStoreDi = injectable(PersonStoreInj, PersonStore);

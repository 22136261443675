export const entities = {
    Passport: 'Passport',
};

export const permissions = {
    Passport: {
        ViewRelatedRequests: 'ViewRelatedRequests',
        EditRelatedRequests: 'EditRelatedRequests',
        UpdateAuthor: 'UpdateAuthor',
        Delete: 'Delete',
        Edit: 'Edit',
    },
    System: {
        ViewPassportList: 'ViewPassportList',
        ViewPassportRegistry: 'ViewPassportRegistry',
        PassportRegistryAdministration: 'PassportRegistryAdministration',
    },
};

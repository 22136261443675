const passport: Record<string, Record<string, string> | string> = {
    passports: 'Мои паспорта',
    passportsNew: 'Паспорта',
    owner: 'Владелец',
    actions: {
        changeOwner: 'Изменить владельца',
    },
    dialog: {
        changeOwnerTitle: 'Назначить нового владельца',
        confirmDeletionInfoText: 'Вы действительно хотите удалить паспорт №{number}?',
    },
    list: {
        number: 'Номер паспорта',
        title: 'Название паспорта',
        owner: 'Заказчик КНТП',
        author: 'Автор',
        campaign: 'Подпрограмма',
        created: 'Дата создания',
        state: 'Состояние',
    },
    registryPagingInfo:
        'Всего {count, number} паспорт{count, plural, one {} few {а} other {ов}}. ' +
        'Отображены c {from, number} по {to, number}',
    registryPagingInfoIfNull: 'Всего 0 паспортов. Отображено 0 паспортов',
    pageCard: {
        title: 'Паспорт заявки',
        requestKNTP: 'Заявка на отбор КНТП',
        customerKNTP: 'Заказчик КНТП',
    },
    requestLink: 'Паспорт заявки',
    tabs: {
        info: 'Информация о КНТП',
        relatedObjects: 'Заявки и отчёты по КНТП',
        contactInformation: 'Контактная информация',
    },
    requestQuestion:
        'Для подачи заявок на гранты, на внесение изменений в паспорт или на подачу отчетных материалов необходимо нажать на кнопку [Создать заявку]',
    relatedTable: {
        number: 'Номер заявки',
        title: 'Форма заявки',
        created: 'Дата создания заявки',
        sent: 'Дата подачи',
        quarter: 'Квартал',
        yearQuarter: 'Год',
        state: 'Состояние',
    },
};

export default passport;

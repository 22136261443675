import fonts from './fonts';
import { AppFonts, AppIcons, AppPalette, ThemeOverrides } from '../themeTypes';

type ColorName =
    | 'black'
    | 'white'
    | 'orange'
    | 'yellowDark'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'red'
    | 'green'
    | 'lighterBlue'
    | 'lighterGrey'
    | 'disabled';

const mcxicsColors: Record<ColorName, string> = {
    black: '#212125',
    white: '#ffffff',
    orange: '#d19b3f',
    yellowDark: '#f5c722',
    greyMain: '#63666a',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    red: '#fb4d3d',
    green: '#008000',
    lighterBlue: '#e8e8e8',
    lighterGrey: 'rgba(133, 147, 143, 0.09)',
    disabled: 'rgba(0, 0, 0, 0.12)',
};

const mcxicsFonts: AppFonts = {
    ...fonts,
    mainFontAttrs: {
        fontSize: '13px',
        lineHeight: 1.69,
        letterSpacing: '0.2px',
    },
};

const mcxicsIcons: AppIcons = {
    fontFamily: fonts.iconsFont,
    arrowIcon: {
        fontSize: 11,
        content: '"\\e90f"',
        width: 12,
        height: 7,
    },
    doneIcon: {
        fontSize: 14,
        content: '"\\e906"',
        width: 14,
        height: 11,
    },
    calendarIcon: {
        fontSize: 20,
        content: '"\\e905"',
        width: 18,
        height: 20,
    },
};

const accentBackgroundGradient = `linear-gradient(-30deg, ${mcxicsColors.orange} 0%, ${mcxicsColors.orange} 100%)`;

export const mcxicsPalette: AppPalette = {
    main: mcxicsColors.black,
    textMain: mcxicsColors.greyMain,
    textDark: mcxicsColors.black,
    mainMiddleLight: mcxicsColors.greyMiddleLight,
    mainLight: mcxicsColors.greyLight,
    mainDark: mcxicsColors.greyDark,
    mainContrast: mcxicsColors.white,
    mainContrastDarker: mcxicsColors.greyLighter,
    accent: mcxicsColors.orange,
    accentDark: mcxicsColors.orange,
    accentContrast: mcxicsColors.black,
    accentBackgroundGradient: accentBackgroundGradient,
    hover: mcxicsColors.orange,
    hoverInLists: mcxicsColors.greyWhite,
    red: mcxicsColors.red,
    green: mcxicsColors.green,
    campaignList: {
        backgroundCard: mcxicsColors.lighterBlue,
        selectedPaginationButton: mcxicsColors.lighterGrey,
    },
    table: {
        row: {
            even: mcxicsColors.white,
            odd: mcxicsColors.greyLighter,
        },
    },
    card: {
        header: {
            main: mcxicsColors.greyLight,
        },
    },
    appBar: {
        mainContrast: mcxicsColors.black,
    },
    button: {
        accentContrast: mcxicsColors.black,
        disabled: mcxicsColors.disabled,
    },
    iconButton: {
        color: mcxicsColors.black,
        hoverColor: mcxicsColors.white,
        hoverBackgroundColor: mcxicsColors.greyMain,
    },
    background: {
        textMain: mcxicsColors.black,
    },
    footer: {
        textMain: mcxicsColors.greyMain,
        padding: 3.75,
    },
    tab: {
        backgroundColor: '#ececec',
        color: mcxicsColors.black,
        hoverBackgroundColor: mcxicsColors.black,
        hoverColor: mcxicsColors.white,
    },
    countButton: {
        backgroundColor: '#ececec',
        color: mcxicsColors.black,
        hoverBackgroundColor: mcxicsColors.black,
        hoverColor: mcxicsColors.white,
    },
    panel: {
        markerBackgroundColor: accentBackgroundGradient,
        markerColor: mcxicsColors.black,
    },
};

export default {
    fonts: mcxicsFonts,
    palette: mcxicsPalette,
    icons: mcxicsIcons,
} as ThemeOverrides;
